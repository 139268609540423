<template>
  <!-- 活动列表 -->
  <div class="active-list pa-3" v-if="orderInProgressList.code">
    <div class="active-list-item">
      {{ orderInProgressList.content }}
      <!-- <a
        href="javascript:;"
        class="ml-2 underline"
        @click="copyFn(orderInProgressList.code)"
        >Get Code</a
      > -->
    </div>
  </div>
  <div reporter-module="page_header">
    <section class="d-none d-lg-block header-container">
      <div class="sp-container">
        <v-row no-gutters class="d-flex justify-center">
          <v-col cols="12">
            <v-row no-gutters class="pt-5">
              <v-col cols="4">
                <img
                  :src="logoSrc"
                  style="max-width: 220px; cursor: pointer; max-height: 60px"
                  alt=""
                  @click="goHome"
                />
              </v-col>
              <v-col cols="4">
                <div reporter-module="header_search_input" class="search-input">
                  <input
                    v-model="keyword"
                    type="text"
                    v-on:keydown="onSearch"
                    placeholder=" products, sku or keywords"
                  />
                  <div class="icon">
                    <v-icon
                      @click="onClick(keyword)"
                      icon="mdi-magnify"
                      size="28"
                    ></v-icon>
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex align-center justify-end h-100">
                  <v-btn
                    class="fs-18 text-primary pl-3 pr-3 cursor-pointer d-flex align-center"
                    @click="trackMyOrder"
                    style="letter-spacing: normal"
                  >
                    <span class="mdi mdi-truck-cargo-container mr-2"></span>
                    <span class="fs-14" style="line-height: 28px"
                      >Order Tracking</span
                    >
                  </v-btn>
                  <shopmall-tip-btn
                    v-if="!store?.userInfo?.userId"
                    :width="260"
                    presentationMethod="hover"
                  >
                    <template #btn="props">
                      <v-btn
                        v-bind="props"
                        variant="text"
                        color="primary"
                        class="px-3"
                      >
                        <v-icon
                          icon="mdi-account-outline"
                          class="mr-1"
                          size="20"
                        ></v-icon
                        >Sign In</v-btn
                      >
                    </template>
                    <template #contBtn>
                      <v-btn color="primary" class="w-100" @click="signIn"
                        >Sign In</v-btn
                      >
                    </template>
                  </shopmall-tip-btn>
                  <!-- <v-divider vertical v-if="store.userInfo.userId"></v-divider> -->
                  <!-- My Account -->
                  <div :key="store.userInfo.userId" style="display: inline-block">
                    <shopmall-tip-btn
                      v-if="store?.userInfo?.userId"
                      :width="store?.userInfo?.status == 1 ? '' : 260"
                      presentationMethod="hover"
                    >
                      <template #btn="props">
                        <v-btn
                          v-bind="props"
                          id="menu-activator"
                          variant="text"
                          color="primary"
                          class="px-3"
                        >
                          <v-icon
                            icon="mdi-account-outline"
                            class="mr-1"
                            size="20"
                          ></v-icon
                          >My Account
                        </v-btn>
                      </template>
                    </shopmall-tip-btn>
                  </div>
                  <v-btn
                    variant="text"
                    color="primary"
                    class="px-3"
                    @click="navigateTo('/my-cart')"
                  >
                    <v-icon icon="mdi-cart" size="18" />
                    ({{ cartStore.cartCounts }})
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="mt-2">
        <shopmall-nav></shopmall-nav>
      </div>
    </section>
    <section class="d-md-block d-lg-none d-none header-container">
      <div class="sp-container">
        <v-row no-gutters class="d-flex justify-center">
          <v-col cols="12">
            <v-row no-gutters class="pt-5">
              <v-col cols="4">
                <img
                  :src="logoSrc"
                  style="max-width: 220px; cursor: pointer"
                  alt=""
                  @click="goHome"
                />
              </v-col>
              <v-col cols="4">
                <div reporter-module="header_search_input" class="search-input">
                  <input
                    v-model="keyword"
                    type="text"
                    v-on:keydown="onSearch"
                    placeholder=" products, sku or keywords"
                  />
                  <div class="icon">
                    <v-icon
                      @click="onClick(keyword)"
                      icon="mdi-magnify"
                      size="28"
                    ></v-icon>
                  </div>
                </div>
              </v-col>
              <v-col cols="4">
                <div class="d-flex align-center justify-end h-100 flex-wrap">
                  <v-btn
                    class="fs-18 text-primary pl-3 pr-3 cursor-pointer d-flex align-center"
                    @click="trackMyOrder"
                    style="letter-spacing: normal"
                  >
                    <span class="mdi mdi-truck-cargo-container mr-2"></span>
                    <span class="fs-14" style="line-height: 28px"
                      >Order Tracking</span
                    >
                  </v-btn>
                  <shopmall-tip-btn
                    v-if="!store?.userInfo?.userId"
                    :width="260"
                    presentationMethod="all"
                  >
                    <template #btn="props">
                      <v-btn
                        v-bind="props"
                        variant="text"
                        color="primary"
                        class="px-3"
                      >
                        <v-icon
                          icon="mdi-account-outline"
                          class="mr-1"
                          size="20"
                        ></v-icon
                        >Sign In</v-btn
                      >
                    </template>
                    <template #contBtn>
                      <v-btn color="primary" class="w-100" @click="signIn"
                        >Sign In</v-btn
                      >
                    </template>
                  </shopmall-tip-btn>

                  <!-- <v-divider vertical v-if="store.userInfo.userId"></v-divider> -->
                  <!-- My Account -->
                  <div :key="store.userInfo.userId" style="display: inline-block">
                    <shopmall-tip-btn
                      v-if="store?.userInfo?.userId"
                      :width="store?.userInfo?.status == 1 ? '' : 260"
                      presentationMethod="all"
                    >
                      <template #btn="props">
                        <v-btn
                          v-bind="props"
                          id="menu-activator"
                          variant="text"
                          color="primary"
                          class="px-3"
                        >
                          <v-icon
                            icon="mdi-account-outline"
                            class="mr-1"
                            size="20"
                          ></v-icon
                          >My Account
                        </v-btn>
                      </template>
                    </shopmall-tip-btn>
                  </div>
                  <v-btn
                    variant="text"
                    color="primary"
                    class="px-3"
                    @click="navigateTo('/my-cart')"
                  >
                    <v-icon icon="mdi-cart" size="18" />
                    ({{ cartStore.cartCounts }})
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="mt-2">
        <shopmall-nav></shopmall-nav>
      </div>
    </section>
    <section class="d-block d-md-none">
      <div class="nopc-device">
        <div class="bar">
          <v-row>
            <v-col cols="4">
              <v-icon
                icon="mdi-reorder-horizontal"
                @click="showMenuDrawer"
              ></v-icon>
            </v-col>
            <v-col cols="4">
              <img
                :src="logoSrc"
                style="max-width: 120px; cursor: pointer"
                alt=""
                @click="goHome"
              />
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-end">
              <span
                class="mdi mdi-truck-cargo-container fs-18 text-primary mr-2 cursor-pointer"
                @click="trackMyOrder"
              ></span>
              <div>
                <shopmall-tip-btn
                  v-if="!store?.userInfo?.userId"
                  :width="180"
                  presentationMethod="click"
                >
                  <template #btn="props">
                    <span
                      class="mdi mdi-account-outline fs-20 text-primary mr-2 cursor-pointer"
                      v-bind="props"
                    ></span>
                  </template>
                  <template #contBtn>
                    <v-btn
                      size="small"
                      color="primary"
                      class="w-100"
                      @click="signIn"
                      >Sign In</v-btn
                    >
                  </template>
                </shopmall-tip-btn>
                <shopmall-tip-btn
                  v-if="store?.userInfo?.userId"
                  :width="180"
                  presentationMethod="click"
                >
                  <template #btn="props">
                    <span
                      class="mdi mdi-account-outline fs-20 text-primary mr-2 cursor-pointer"
                      v-bind="props"
                    ></span>
                  </template>
                </shopmall-tip-btn>
              </div>
              <span
                class="mdi mdi-cart fs-18 text-primary cursor-pointer"
                @click="navigateTo('/my-cart')"
              ></span>
            </v-col>
          </v-row>
          <!-- drawer -->
          <shopmall-drawer
            ref="menuDrawer"
            :width="780"
            location="left"
            class="menu-wrapper"
          >
            <template #header>
              <div class="menu-title d-flex justify-space-between px-4 py-2">
                <p v-if="menus.some((menu) => menu.level !== 1)" @click="back">
                  <v-icon icon="mdi-arrow-left"></v-icon>
                  <span class="ml-2">Back</span>
                </p>

                <p v-if="!menus.some((menu) => menu.level !== 1)">
                  Shop By Category
                </p>
                <v-icon
                  icon="mdi-close"
                  class="ms-4"
                  v-if="menus.every((menu) => menu.level == 1)"
                  @click="showMenuDrawer"
                ></v-icon>
              </div>
            </template>
            <template #default>
              <div class="pa-4">
                <div
                  v-if="menus.some((menu) => menu.level !== 1)"
                  class="fb-600 mb-8 fc-black"
                  @click="
                    clickMenuItem({
                      nameEn: productStore.parentMenuName
                    })
                  "
                >
                  ALL&nbsp;{{ productStore.parentMenuName }}
                </div>
                <div
                  v-for="menu in menus"
                  class="d-flex justify-space-between mb-5"
                  @click.stop="clickMenuItem(menu)"
                >
                  <p>{{ menu.nameEn }}</p>
                  <v-icon
                    icon="mdi-chevron-right"
                    class="ms-4"
                    v-if="menu.children && menu.level == 1"
                  ></v-icon>
                </div>
              </div>
            </template>
          </shopmall-drawer>
        </div>

        <div class="search">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="keyword"
                variant="outlined"
                class="mobile-header-search"
                density="compact"
                label="Search all products, sku or keywords"
                append-inner-icon="mdi-magnify"
                single-line
                hide-details
                @click:append-inner="onClick(keyword)"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { promotionsInteractor, systemInteractor, productInteractor, authInteractor } from "~/core";
import { useUserStore, useProductStore, useCartStore, useSearchStore } from "~/stores";
import { SearchClickReport, SearchSuccessReport, SearchResultReport, trackSearch } from '~/utils/report/search'
const { 
  $MallPageName, 
  $ClickSource, 
  $BaseClickBurying, 
  $Message, 
  $Utils,
  $multiPlatform,
  $Buryingpoint,
  $Reporter
} = useNuxtApp();
const primaryColor = $multiPlatform.primaryColor;  
const logoSrc = $multiPlatform.logo
const cartStore = useCartStore()
const route = useRoute()
const router = useRouter()
const keyword = ref("")
const menuDrawer = ref(null)
const orderInProgressList = ref({}) //折扣活动
const showMenuDrawer = () => {
  menuDrawer.value.show()
}
const signIn = () => {
  navigateTo("/account/signin")
}
// 获取购物车数量
const cart = useCart()
cart.initCartData(false)

// 定义menus菜单，菜单项可能有children
// 给menus每个一级元素添加level:1属性
const category = useCategory()
const productStore = useProductStore()
// 菜单
const menus = ref([])

const getMenus = async () => {
  let menusData = await category.getCategorys()
  menusData = menusData.map((item) => {
    item.level = 1
    if (item.children && item.children.length > 0) {
      item.children = item.children.map((s) => {
        s.level = 2
        return s
      })
    } else {
      item.children = null
    }
    return item
  })
  menus.value = menusData
}
// 商城-查询进行中的订单折扣
const getOrderInProgressFn = async () => {
  const cachedOrderInProgress = localStorage.getItem("orderInProgressList")
  if (cachedOrderInProgress) {
    orderInProgressList.value = JSON.parse(cachedOrderInProgress)
  }
  const [res, err] = await promotionsInteractor.orderInProgressApi()
  if (res) {
    orderInProgressList.value = res
    localStorage.setItem("orderInProgressList", JSON.stringify(res))
  } else if (!res && !err) {
    localStorage.removeItem("orderInProgressList")
    orderInProgressList.value = []
  }
}
onMounted(() => {
  getOrderInProgressFn()
  getMenus()
})
let beforeMenu = ref([])

// 点击菜单
const clickMenuItemArrow = (menu, e) => {
  productStore.setParentMenuName(menu.nameEn)
  beforeMenu.value = menus.value
  menus.value = menu.children
}
//点击trackMyOrder
const trackMyOrder = () => {
  if (store?.userInfo?.userId) {
    router.push({
      path: "/my-account/order-tracking"
    })
  } else {
    router.push({
      path: "/my-account/tracking"
    })
  }
}
// 菜单项点击
const clickMenuItem = (menu) => {
  if (menu.children && menu.level === 1) {
    return clickMenuItemArrow(menu)
  }
  if(route.name === "index") {
    category.setCategorysInfo(menu,'','home');
  }else {
    category.setCategorysInfo(menu)
  }
  if (productStore.accessories === menu.nameEn) {
    return showMenuDrawer()
  }
}

// 返回 使用箭头函数
const back = () => {
  menus.value = beforeMenu.value
}

const searchStore = useSearchStore();

/**
 * 跳转到搜索页
 * @param {keyword} 关键词
 */

const onClick = async (keyword) => {
  if (!keyword?.trim()) {
    $Message.error("Please enter a valid search term.")
    return
  }

  // 搜索按钮点击事件埋点
  $BaseClickBurying($MallPageName.mallHeader, $ClickSource.mallHeaderSearch)
  $Reporter.click({event: 'e_header_search_btn'})

  // 获取版本号
  const [searchVersion,err] = await systemInteractor.searchVersionApi()
  if(searchVersion){
    localStorage.setItem('searchVersion', searchVersion)
  }else{
    localStorage.removeItem('searchVersion')
  }

  // 判断搜索是否是通过用户交互触发的标示
  localStorage.setItem('searchSourceClick', 1)
  
  // 搜索直接跳转到商品详情页
  const searchParams = { keyWord: keyword, current: 1, size: 40, orders: [] }
  const [res, error] = await productInteractor.productPage(searchParams);
  if (res && res.total === 1 && res.precisionSearch) {
    const product = res.records[0];
    SearchClickReport(keyword, true)
    SearchSuccessReport(keyword, true)
    SearchResultReport(keyword, true)
    $Buryingpoint.searchKeywordProductClick({
      search_keyword: keyword,
      product_name: product.nameEn
    });
    trackSearch(keyword, product.nameEn)
    navigateTo(`/product/${product.code}_${$Utils.replaceSpacesWithDash(product.nameEn)}${`?t=s&k=${keyword}&source=header_search`}`);
  } else if(res) {
    searchStore.setSearchResult(res)
    // 重复的请求数据也可以搜索
    route.query.keyword = "";
    const queryKeyword = encodeURIComponent(keyword);
    navigateTo(`/search?keyword=${queryKeyword}`);
  }
};

/**
 * 搜索栏键入事件
 * @param {event}
 */
const onSearch = (event) => {
  if (event.key === "Enter") onClick(keyword.value)
}

// 登录状态
const store = useUserStore()

// 登出
const signOut = async () => {
  const [res, err] = await authInteractor.loginOutApi()
  if (res === "") {
    store.resetAll()
    if (
      route.path.match("product") ||
      route.path.match("category") ||
      route.path.match("search") ||
      window.location.pathname === "/"
    ) {
      window.location.reload()
    } else {
      router.push({
        name: "index"
      })
    }
  }
}

// 跳转到首页
const goHome = () => {
  if (router?.currentRoute?.value?.name === "index") {
    const link = document.createElement("a")
    link.href = "/"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } else {
    navigateTo("/")
  }
}
const copyFn = async (val) => {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(val)
    $Message.success("Get Success")
  } else {
    var tempTextArea = document.createElement("textarea")
    tempTextArea.value = val
    document.body.appendChild(tempTextArea)
    tempTextArea.select()
    document.execCommand("copy")
    document.body.removeChild(tempTextArea)
    $Message.success("Get Success")
  }
}
</script>

<style lang="scss" scoped>
.btn-wrapper {
  display: inline-block;
}
.header-container {
  /* height: 80px; */
  border-bottom: 1px solid #e5e5e5;
  --primary-color: v-bind(primaryColor);
}
.v-divider {
  height: 14px;
}
.v-btn {
  font-size: 14px;
}
.search-input {
  position: relative;
  input {
    height: 44px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    padding: 0 50px 0 15px;
    font-size: 14px;
    outline: none;
  }
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 100%;
    color: white;
    background: var(--primary-color);
    border-radius: 0 4px 4px 0;
    .v-icon {
      cursor: pointer;
      line-height: 100%;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.search-input input:focus {
  border: 2px solid var(--primary-color);
}

.nopc-device {
  .bar {
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
    i {
      cursor: pointer;
      color: #b1b1b1;
    }
    i:hover {
      color: #222;
    }
  }
  .search {
    padding: 8px;
    border-bottom: 1px solid #e5e5e5;
  }
}
:deep(.v-label) {
  font-size: 14px;
}
.menu-wrapper {
  color: #555;
  .menu-title {
    background-color: #f2f2f2;
  }
}
:deep(.v-navigation-drawer) {
  width: 75% !important;
}
:deep(.mobile-header-search .v-field__overlay) {
  border: 1px solid #d7d7d7;
}
.cursor-pointer {
  cursor: pointer !important;
}
.active-list {
  width: 100%;
  background: #016490;
  .active-list-item {
    text-align: center;
    color: #fff;
    font-size: 19px;
    line-height: 32px;
    a {
      color: #fff;
    }
  }
  .underline {
    text-decoration: underline;
  }
}
</style>
