<template>
  <nav class="shopmall-nav-new">
    <ul>
      <li v-for="(item, index) in navItems" :key="item.nameEn" @click="onClick({ nameEn: item.nameEn })">
        <div class="nameEn" @mouseenter="showOverlay($event, item, index)" @mouseleave="hideOverlay">
          <div class="nameEn-text">
            {{ item.nameEn }}
            <img
              :class="[item.underlineVisible?'chevron-up':'chevron-down']" v-if="['Products','Featured Products'].includes( item.nameEn)"
              src="~/assets/imgs/chevron-down.svg"
              width="18"
              heigth="18"
            />
          </div>
          <!-- <v-icon icon="mdi-chevron-down" :class="[item.underlineVisible?'chevron-up':'chevron-down']" v-if="['Products','Featured Products'].includes( item.nameEn)"></v-icon> -->
          <div v-if="item.underlineVisible" class="underline"></div>
        </div>
        <span class="separator">|</span>
      </li>
    </ul>
    <!-- 遮罩层 -->
    <div
      :style="{
        visibility: overlayVisible ? 'visible' : 'hidden',
        opacity: Number(overlayVisible),
        transition: isLeave ? '' : 'visibility 0s ease-in-out, opacity 0s ease-in-out',
        paddingLeft: paddingLeft + 'px',
        paddingTop: (classifyList.length ? 20 : 0) + 'px',
        paddingBottom: (classifyList.length ? 20 : 0) + 'px',
      }"
      class="overlay"
      @mouseenter="keepOverlay"
      @mouseleave="hideOverlay"
      reporter-module="header_nav_products_menu"
    >
      <div class="classify-wrapper">
        <div class="classify">
          <div class="classify-item" v-for="(item, index) in classifyList" :key="item.id">
            <div class="classify-item-name" @mouseenter="showSubClassify(item, index)" @click="onClick(item)" :style="{ color: activeName === item.nameEn ? '#00bbef' : '#000' }">
              {{ item.nameEn }}
            </div>
          </div>
        </div>
        <!-- 只有Products才有二级分类 -->
        <div v-if="subList && subList.length && showImgs" class="sub-classify">
          <div v-for="subItem in subList" :key="subItem.id" @click="onClick(subItem)">
            {{ subItem.nameEn }}
          </div>
        </div>
      </div>
      <div reporter-module="header_nav_products_menu_img" v-show="showImgs" class="imgs">
        <div v-for="item of imgs" :key="item.id">
          <img :src="item.imageUrl" @click="onImgClick(item)" />
          <p>{{ item.title }}</p>
          <p>{{ item.introduce }}</p>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { debounce } from "kits"
import { productInteractor } from "~/core"
const { $Utils,$Bus } = useNuxtApp()
const { myLocalStorage } = $Utils
const router = useRouter()
const props = defineProps({ menus: { type: Array, default: () => [] }, productIds: { type: Array, default: () => [] }, brandIds: { type: Array, default: () => [] } })
const navItems = ref(props.menus)
const overlayVisible = ref(false) // 是否显示遮罩层
const curIndex = ref(-1) // 当前高亮的索引
const classifyList = ref([]) // 一级分类列表
const subList = ref([]) // 二级分类列表
const paddingLeft = ref(0) // 遮罩层左侧内边距
const showImgs = ref(false) // 是否显示图片
const isLeave = ref(false) // 是否是点击跳转离开，如果是，遮罩层不加动画；如果不是，遮罩层加动画
const imgs = ref([]) // Products下面图片列表
const activeName = ref("") // 当前激活的一级分类名称

// 显示遮罩层
const showOverlay = (e, item, index) => {
  const { left } = e.target.getBoundingClientRect()
  paddingLeft.value = left
  navItems.value.forEach((n) => (n.underlineVisible = false))
  navItems.value[index].underlineVisible = true
  overlayVisible.value = true
  curIndex.value = index
  classifyList.value = item.children
  showImgs.value = item.nameEn === "Products"
  $Bus.emit("hide-select-menu")
}

// 隐藏遮罩层
const hideOverlay = () => {
  navItems.value.forEach((n) => (n.underlineVisible = false))
  overlayVisible.value = false
  imgs.value = [] // 清除图片列表
  subList.value = [] // 清空二级分类列表
  activeName.value = ''
}

const keepOverlay = (bool = true) => {
  navItems.value[curIndex.value].underlineVisible = true
  overlayVisible.value = true
}

// 显示二级分类
let showSubClassify = () => {}
// 点击跳转
const route = useRoute()
const onClick = ({ id, nameEn, children, queryTypeName, queryType }) => {
  if (["Products", "Featured Products"].includes(nameEn)) return // 点击New Arrivals或Brands以外的导航，直接return
  beforeJump()
  const source = route.name === "index" ? { source: 'new_home' } : {}
  // 导航中只有New Arrivals和"Brands"可以点击跳转
  if (nameEn === "New Arrivals") {
    router.push({ path: "/new-arrivals",query: source })
    return
  }
  if (nameEn === "Brands") {
    router.push({ path: "/brands",query: source })
    return
  }
  // 如果是Brands下的分类，点击跳转----目前产品需要将brands下面的一级分类隐藏，如果需要显示，需要将下面的注释打开
  // if (props.brandIds.includes(id)) {
  //   router.push({ path: "/brands", query: { brandName: nameEn, id } })
  //   return
  // }
  // 如果是Featured Products下的分类，点击跳转
  if (queryTypeName) {
    router.push({ path: "/featured-products", query: { queryTypeName: encodeURIComponent(queryTypeName), queryType, ...source } })
    return
  }
  // 如果是Products下的分类，点击跳转
  if (nameEn) {
    nameEn = $Utils.replaceSpacesWithDash(nameEn, false)
    router.push({ path: `/category/${nameEn}`, query: source })
  }
}

const beforeJump = () => {
  setTimeout(() => {
    hideOverlay()
    isLeave.value = true
    setTimeout(() => {
      isLeave.value = false
    }, 1000)
  })
}

// 点击图片跳转
const onImgClick = ({ productCode, categoryNameEn, id }) => {
  beforeJump()
  router.push(`/product/${productCode}_${$Utils.replaceSpacesWithDash(categoryNameEn)}?products_recommend_id=${id}`)
}

onBeforeMount(() => {
  showSubClassify = debounce((item, index) => {
    getProductHomePage(item.id)
    subList.value = item.children
    activeName.value = item.nameEn
  }, 300)
})

onMounted(() => {
  isLeave.value = false
  removeCategoryImgs()
})

// 获取类目产品图片
const getProductHomePage = async (id) => {
  if (!props.productIds.includes(id)) return
  const categoryImgs = myLocalStorage.get("categoryImgs", id)
  if (categoryImgs) {
    imgs.value = categoryImgs
  } else {
    const [res, err] = await productInteractor.recommendProductApi(id)
    const list = !err && res?.length ? res.slice(0, 2) : []
    imgs.value = list
    myLocalStorage.set("categoryImgs", id, list, 1000 * 60 * 30) // 存储时间为半小时
  }
}

// 刷新浏览器时-删除类名产品图片缓存
const removeCategoryImgs = (event) => {
  if (performance?.getEntriesByType) {
    const [entry] = performance.getEntriesByType("navigation")
    if (entry.type === "reload") {
      localStorage.removeItem("categoryImgs")
    }
  }
}
</script>

<style lang="scss" scoped>
.shopmall-nav-new {
  position: relative;
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    li {
      display: flex;
      align-items: center;
      .nameEn {
        position: relative;
        line-height: 50px;
        padding: 0 15px;
        cursor: pointer;
        .nameEn-text {
          display: flex;
          img {
            margin-left: 8px;
            margin-top: 6px;
          }
        }
        .underline {
          // 动态宽度
          // position: absolute;
          // bottom: 0;
          // left: 0;
          // right: 0;
          // height: 2px;
          // background-color: #fff;
          // 固定宽度
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 140px;
          height: 2px;
          background-color: #fff;
        }
      }
      .separator {
        margin: 0 40px;
      }
      &:last-of-type {
        .separator {
          display: none;
        }
      }
    }
  }
  // 遮罩层
  .overlay {
    position: absolute;
    z-index: 100;
    top: 50px;
    left: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    .classify-wrapper {
      display: flex;
      align-items: baseline;
      // 一级分类
      .classify {
        font-size: 14px;
        font-weight: bold;
        .classify-item {
          margin-top: 20px;
          padding-right: 20px;
          position: relative;
          display: flex;
          line-height: 18px;
          width: 222px;
          &:first-of-type {
            margin-top: 0;
          }
          .classify-item-name {
            max-width: 222px;
            cursor: pointer;
          }
        }
      }
      // 二级分类
      .sub-classify {
        width: 500px;
        padding-left: 20px;
        border-left: 1px solid #ddd;
        display: flex;
        flex-wrap: wrap;
        > div {
          width: 50%;
          line-height: 18px;
          font-size: 14px;
          cursor: pointer;
          font-weight: normal;
          // 一行2个，从第二行开始，每个li设置margin-top为10
          &:nth-of-type(n + 3) {
            margin-top: 10px;
          }
          &:hover {
            color: #00bbef;
          }
        }
      }
    }
    // 图片
    .imgs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > div {
        width: 230px;
        &:first-of-type {
          margin-right: 20px;
        }
        > img {
          width: 230px;
          height: 123px;
          border-radius: 5px;
          cursor: pointer;
          object-fit: cover;
        }
        > p {
          font-size: 14px;
          &:first-of-type {
            margin-top: 10px;
            width: 100%;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &:last-of-type {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #9fa1a2;
          }
        }
      }
    }
  }
  /* 导航栏icon图标动画 */
  .chevron-up {
    transform: rotateX(180deg);
    transition: all 0.3s ease-in-out;
  }
  .chevron-down {
    transform: rotateX(0deg);
    transition: all 0.3s ease-in-out;
  }
}
</style>
