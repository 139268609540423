<template>
  <div class="page-footer-new" reporter-module="page_footer" reporter-appear>
    <div v-if="!mdAndDown" class="pc container">
      <div class="top">
        <div class="texts">
          <p>From pre-production to delivery</p>
          <p>We provide expert care, friendly consultation, and peace of mind.</p>
        </div>
        <div class="imgs">
          <img :src="sageImg" />
        </div>
      </div>
      <div class="middle">
        <img :src="logoSrc" />
        <ul>
          <li v-for="item of pcLinks" :key="item.path" @click="goPage(item)">{{ item.year ? item.year + " " : "" }}{{ item.text }}</li>
        </ul>
      </div>
      <div class="bottom">
        <div class="imgs">
          <img v-for="item of bottomImgs" :key="item.path" :src="item.src" @click="goUrl(item.path)" />
        </div>
        <p>© {{ usYeaer }} {{ $multiPlatform.companyName }} Inc. All rights reserved.</p>
      </div>
    </div>
    <div v-else class="mobile container">
      <p class="">From pre-production to delivery</p>
      <p>We provide expert care, friendly consultation, and peace of mind.</p>
      
      <div class="middle">
        <div class="img-logo-sage">
          <img :src="logoSrc" />
          <img :src="sageImg" class="img-sage" />
        </div>
        <ul>
          <li v-for="item of mLinks" :key="item.path" @click="goPage(item)">{{ item.text }}</li>
        </ul>
      </div>
      <div class="bottom">
        <div class="imgs">
          <img v-for="item of bottomImgs" :key="item.path" :src="item.src" @click="goUrl(item.path)" />
        </div>
        <p>© {{ usYeaer }} {{ $multiPlatform.companyName }} Inc. All rights reserved.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify"
import trustwave from "~/assets/imgs/trustwave.jpg"
import u109 from "~/assets/imgs/u222.jpg"
import { productInteractor } from "~/core"
import { useUserStore } from "~/stores"
const store = useUserStore()
const { $BaseClickBurying, $MallPageName, $ClickSource, $multiPlatform, $Reporter } = useNuxtApp()
const { mdAndDown } = useDisplay()
const router = useRouter()
const usYeaer = ref("2025") //美国年份

const sageImg = $multiPlatform.sageLogoRP2025

const logoSrc = $multiPlatform.logoNew

const mLinks = ref([
  { text: "Terms of Use", path: "/terms-and-conditions" },
  { text: "Privacy Policy", path: "/privacy-policy" },
  { text: "Contact Us", path: "/my-account/contactus" },
])
const pcLinks = ref(JSON.parse(JSON.stringify(mLinks.value)))

const bottomImgs = ref([
  { src: trustwave, path: "https://www.geotrust.com/" },
  { src: u109, path: "https://www.pcisecuritystandards.org/" },
])

const goPage = ({ text, path }) => {
  if (text === "Product Catalog") {
    $BaseClickBurying($MallPageName?.mallProductCatalog, $ClickSource?.mallProductCatalog)
    $Reporter.click({event: "e_product_catalog"})
    window.open(path, "_blank")
  } else {
    router.push({ path })
  }
}

const goUrl = (path) => {
  const link = document.createElement("a")
  link.href = path
  link.target = "_blank"
  link.click()
}

const getProductCatalog = async () => {
  if (!mdAndDown.value) {
    const [res, err] = await productInteractor.getProductCatalogApi()
    if (!err) {
      if (res?.year && res?.link && store?.userInfo?.status == 1) {
        pcLinks.value.unshift({ text: "Product Catalog", year: res.year, path: res.link })
      }
    }
  }
}

onMounted(() => {
  getProductCatalog()
})
</script>

<style lang="scss" scoped>
.page-footer-new {
  background-color: #0e274f;
  color: #fff;

  @media (max-width: 1450px) {
    .pc.container {
      padding: 40px 100px !important;
    }
  }
  @media (min-width: 1450px) and (max-width: 1720px) {
    .pc.container {
      padding: 40px 200px !important;
    }
  }
  .pc.container {
    padding: 40px 20%;
    min-width: 1000px;
    margin: 0 auto;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .texts {
        p {
          &:first-of-type {
            font-size: 30px;
            font-weight: bold;
          }
          &:last-of-type {
            font-size: 16px;
          }
        }
      }
      .imgs {
        display: flex;
        justify-content: flex-end;
        width: 56%;
        img {
          width: 18%;
          max-width: 120px;
        }
      }
    }
    .middle {
      margin: 50px 0 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > img {
        width: 300px;
      }
      > ul {
        display: flex;
        li {
          cursor: pointer;
          & + li {
            margin-left: 30px;
          }
        }
      }
    }
    .bottom {
      .imgs {
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 80px;
          cursor: pointer;
          & + img {
            margin-left: 40px;
          }
        }
      }
      > p {
        margin-top: 20px;
        text-align: center;
      }
    }
  }
  .mobile.container {
    padding: 20px 0;
    > p {
      margin-left: 10%;
      padding-right: 20px;
      &:first-of-type {
        font-size: 20px;
        font-weight: 600;
      }
    }
    @media (max-width: 400px) {
      > .imgs {
        padding: 0 20px !important;
      }
    }
    @media (min-width: 400px) and (max-width: 700px) {
      > .imgs {
        width: 400px;
      }
    }
    @media (max-width: 770px) {
      .middle {
        flex-direction: column;
        align-items: center;
        > ul {
          margin-top: 20px;
        }
        > img {
          margin-right: 0 !important;
        }
      }
    }
    .middle {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .img-logo-sage {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 200px;
        }
        .img-sage {
          max-width: 70px;
          margin: 0 20px;

        }
      }
      > img {
        width: 200px;
        margin-right: 20px;
      }
      > ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          font-size: 14px;
          cursor: pointer;
          & + li {
            margin-left: 20px;
          }
        }
      }
    }
    .bottom {
      margin-top: 50px;
      .imgs {
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 80px;
          cursor: pointer;
          & + img {
            margin-left: 40px;
          }
        }
      }
      > p {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}
</style>
