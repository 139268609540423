import { productInteractor } from "~/core";
import { randomHashId } from '~/public/js/utils';
import { searchKeywordClick, searchKeywordClickSuccess, searchKeywordClickResult } from "~/plugins/buryingpoint";

const searchReportMap = {
  click: {
    search: 't_search_keyword_click',
    home_to_search: 't_index_search_keyword_click',
  },
  success: {
    search: 't_search_keyword_success',
    home_to_search: 't_index_search_keyword_success',
  },
  result: {
    search: 't_search_keyword_result',
    home_to_search: 't_index_search_keyword_result',
  }
}

const searchReport = (key, keyword, direct = false) => {
  const { $Reporter } = useNuxtApp()
  const route = useRoute()
  const tracks = $Reporter.getTrackList()
  const t1 = tracks[0] || {}
  
  $Reporter.track({event: searchReportMap[key].search, search_keyword: keyword})
  // 上一页是首页 或 当前页是首页且直达详情
  if (t1.pageName === 'index' || (route.name === 'index' && direct)) {
    $Reporter.track({event: searchReportMap[key].home_to_search, search_keyword: keyword})
  }
}

let searchSourceType = 1
export const SearchClickReport = (keyword, direct) => {
  // 获取搜索行为标示存起来 然后删除本地存储 来区别用户是通过点击搜索框还是复制或刷新页面来访问触发
  searchSourceType = localStorage.getItem("searchSourceClick") ? 1 : 2
  localStorage.removeItem("searchSourceClick")

  const reportQuery = {
    keyWord: keyword,
    type: 1,
    source: searchSourceType,
    id: randomHashId(),
    version: localStorage.getItem("searchVersion") || ''
  }
  productInteractor.reportSearchApi({...reportQuery, method: 'request'})

  searchKeywordClick(keyword, () => {
    productInteractor.reportSearchApi({...reportQuery, method: 'callback'})
  });
  
  searchReport('click', keyword, direct)
}

export const SearchSuccessReport = (keyword, direct) => {
  const reportQuery = {
    keyWord: keyword,
    type: 2,
    source: searchSourceType,
    id: randomHashId(),
    version: localStorage.getItem("searchVersion") || ''
  }
  productInteractor.reportSearchApi({...reportQuery, method: 'request'})
  searchKeywordClickSuccess(keyword, () => {
    productInteractor.reportSearchApi({...reportQuery, method: 'callback'})
  });

  searchReport('success', keyword, direct)
}

export const SearchResultReport = (keyword, direct) => {
  const reportQuery = {
    keyWord: keyword,
    type: 3,
    source: searchSourceType,
    id: randomHashId(),
    version: localStorage.getItem("searchVersion") || ''
  }
  productInteractor.reportSearchApi({...reportQuery, method: 'request'})
  searchKeywordClickResult(keyword, () => {
    productInteractor.reportSearchApi({...reportQuery, method: 'callback'})
  });

  searchReport('result', keyword, direct)
}

// 搜索到产品的特殊链路逻辑
export const trackSearch = (search_keyword, product_name) => {
  const { $Reporter } = useNuxtApp()
  const route = useRoute()
  const currentPageName = route.name
  const tracks = $Reporter.getTrackList()
  const t1 = tracks[0] || {}
  
  $Reporter.track({ event: 't_search_to_product', search_keyword, product_name })
  if (currentPageName === 'index' || t1.pageName === 'index') {
    $Reporter.track({ event: 't_index_search_to_product', search_keyword, product_name })
  }
}