import { UAParser } from 'ua-parser-js'
export const getClientInfo = (ua) => {
  if (!ua) {
    return {}
  }

  let clientType = "1";
  const uaLower = ua.toLowerCase();
  if (/ipad|ipod/.test(uaLower)) {
    clientType = "0";
  } else if (/android|iphone/.test(uaLower)) {
    clientType = "0";
  } else {
    clientType = "1";
  }

  const parser = new UAParser(ua);
  const parserResults = parser.getResult();

  return {
    browser: parserResults.browser.name,
    browserVersion: parserResults.browser.version,
    clientSystem: parserResults.os.name + ' ' + parserResults.os.version,
    clientType
  }
}

export const getDeviceType = (type) => {
  if (type === 'tablet') {
    return 'tablet';
  } else if (type ==='mobile') {
    return 'mobile';
  } else {
    return 'pc';
  }
}

// 获取添加购物车埋点参数
export const getAddCartTrackParam = (track = '') => {
  return track.split('/ADDCART/')[1] || ''
}

/**
 * 处理URL导航
 * @param {string} url - 要处理的URL
 */
export function handleUrlNavigation(url, querys = []) {
  const currentUrl = new URL(url, window.location.href);
  
  if (currentUrl.origin === window.location.origin) {
    // 同源URL添加查询参数并导航
    querys.forEach((query) => {
      currentUrl.searchParams.append(query.key, query.value);
    });
    navigateTo(currentUrl.pathname + currentUrl.search);
  } else {
    // 外部URL在新标签页打开
    window.open(currentUrl.href, '_blank');
  }
}