import { UAParser } from 'ua-parser-js'
import { getDeviceType } from '~/utils'
import { useGlobalStore } from "~/stores"

// 上报类型映射的map
export const REPORTER_TYPE_MAP = {
  // 页面访问事件
  1: 'pv',
  // 点击事件、函数插桩等
  2: 'click',
  // 链路追踪事件，如某某页到某某页等
  3: 'tracking',
  // 曝光事件，如某某元素、模块曝光
  4: 'exposure',
  // 曝光时长事件，如某某元素曝光时长
  5: 'exposure_duration'
}

// google gtag 上报
let client_type = ''
let pointEvents = []
export const googleReport = (reports) => {
  // 商城的默认上报字段
  if (!client_type) {
    const ua = new UAParser(navigator.userAgent)
    client_type = getDeviceType(ua.getResult().device.type || '')
  }
  const user_name = JSON.parse(localStorage.getItem('user') || '{}')?.userInfo?.loginName || ''
  const is_logged_in = user_name ? '1' : '0'
  const search_version = localStorage.getItem("searchVersion")
  const gStore = useGlobalStore()
  const version = gStore.isNewVersion ? 'new' : 'old'
  
  const mallDefaultData = { user_name, client_type, is_logged_in, search_version, version }

  if (window?.gtag) {
    reports.forEach(item => {
      window.gtag('event', REPORTER_TYPE_MAP[item.type], { ...mallDefaultData, ...item.params, rid: item.rid })
    })
  } else {
    pointEvents = [...pointEvents, reports]
  }
}

// google gtag 未加载完成时的上报先暂存到数组中
export function gtagStagEvents() {
  // console.log('gtag未加载完成，暂存事件', pointEvents)
  if (pointEvents.length > 0) {
    pointEvents.forEach(reports => googleReport(reports))
    pointEvents = []
  }
}
