export const useGlobalStore = defineStore("global", {
  state() {
    return {
      isNewVersion: false,
      isPc: true,
      DNSValid:false
    };
  },

  actions: {
    setNewVersion(flag) {
      this.isNewVersion = flag;
    },
    setDeviceType(value) {
      this.isPc = value;
    },
    setDNSValid(valid) {
      this.DNSValid = valid;
    }
  }
});