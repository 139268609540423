import Reporter from '@wewoo/reporter'
import { googleReport } from '~/utils/report/gtag.report'

export default defineNuxtPlugin(nuxtApp => {
  const router = useRouter()
  nuxtApp.vueApp.use(Reporter, {
    businessId: 1,
    appear: true,
    tracking: true,
    spapv: true,
    router,
    report: (reports) => googleReport(reports)
  })

  nuxtApp.provide('Reporter', nuxtApp.vueApp.config.globalProperties.$Reporter)
})