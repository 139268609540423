<template>
  <div class="page-header-new" reporter-module="page_header">
    <!-- PC端 -->
    <div v-if="gStore.isPc" class="header-container">
      <div class="header-top d-flex align-center justify-space-between">
        <img :src="logoSrc" style="max-width: 280px; cursor: pointer; max-height: 60px" alt="" @click="goHome" />
        <div class="d-flex align-center">
          <div reporter-module="header_search_input" class="search-input">
            <input v-model="keyword" type="text" v-on:keydown="onSearch" placeholder=" products, sku or keywords" />
            <div class="icon" @click="onClick(keyword)">
              <v-icon icon="mdi-magnify" size="28"></v-icon>
            </div>
          </div>
          <div class="d-flex align-center justify-end h-100 top-right-link">
            <router-link :to="store?.userInfo?.userId ? '/my-account/order-tracking' : '/my-account/tracking'" class="fs-18 text-white pl-3 pr-3 d-flex align-center">
              <img :src="orderTrackSvg" width="16" />
              <span class="fs-14 pl-1">Order Tracking</span>
            </router-link>
            <template v-if="!store?.userInfo?.userId">
              <router-link to="/account/signin" class="fs-18 text-white pl-3 pr-3 d-flex align-center">
                <img :src="signinSvg" width="14" height="14" />
                <span class="fs-14 pl-1">Sign In</span>
              </router-link>
              <router-link to="/account/signup" class="fs-18 text-white pl-3 pr-3 d-flex align-center">
                <img :src="register" width="14" height="14" />
                <span class="fs-14 pl-1">Register</span>
              </router-link>
            </template>
            <div :key="store.userInfo.userId" style="display: inline-block">
              <shopmall-tip-btn v-if="store?.userInfo?.userId" :width="store?.userInfo?.status == 1 ? '' : 260" presentationMethod="hover">
                <template #btn="props">
                  <v-btn v-bind="props" id="menu-activator" variant="text" color="white" class="px-3"> <v-icon icon="mdi-account-outline" class="mr-1" size="20"></v-icon>My Account </v-btn>
                </template>
              </shopmall-tip-btn>
            </div>
            <router-link to="/my-cart" class="fs-18 px-3 text-white d-flex align-center">
              <!-- <v-icon icon="mdi-cart" size="18" /> -->
              <img :src="cartSvg" width="14" height="14" />
              <span class="fs-14 pl-1">({{ cartStore.cartCounts }})</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <shopmall-nav-new :menus="menus" :productIds="productIds" :brandIds="brandIds"></shopmall-nav-new>
      </div>
    </div>

    <!-- 移动端 -->
    <div v-else class="nopc-device">
      <div class="top" style="height: 54px;">
        <v-icon icon="mdi-reorder-horizontal" @click="showMenuDrawer"></v-icon>
        <img :src="logoSrc" @click="goHome" />
        <div class="icons">
          <router-link :to="store?.userInfo?.userId ? '/my-account/order-tracking' : '/my-account/tracking'">
            <img :src="orderTrackSvg" class="mr-3" width="16" style="transform: translateY(1px);" />
          </router-link>
          <div>
            <div v-if="!store?.userInfo?.userId" class="d-flex align-center">
              <router-link to="/account/signin">
                <img :src="signinSvg" width="14" height="14" class="mr-3" />
              </router-link>
              <router-link to="/account/signup">
                <img :src="register" width="14" height="14" class="mr-3" />
              </router-link>
            </div>
            <shopmall-tip-btn v-if="store?.userInfo?.userId" :width="180" presentationMethod="click">
              <template #btn="props">
                <span class="mdi mdi-account-outline fs-20 text-white mr-2 cursor-pointer" v-bind="props"></span>
              </template>
            </shopmall-tip-btn>
          </div>
          <router-link to="/my-cart">
            <img :src="cartSvg" width="14" height="14" class="cursor-pointer" />
          </router-link>
        </div>
      </div>

      <shopmall-drawer ref="menuDrawer" :width="780" location="left" class="menu-wrapper">
        <template #header>
          <div class="menu-title d-flex justify-space-between px-4 py-2">
            <template v-if="currentList.some((n) => n.level === 1)">
              <p>Shop By Category</p>
              <v-icon icon="mdi-close" class="ms-4" @click="showMenuDrawer"></v-icon>
            </template>
            <p v-else @click="back">
              <v-icon icon="mdi-arrow-left"></v-icon>
              <span class="ml-2">Back</span>
            </p>
          </div>
        </template>
        <template #default>
          <div reporter-module="header_nav_products_menu" class="pa-4">
            <div v-if="currentList.every((n) => n.level !== 1)" class="fb-600 mb-8 fc-black" @click="handleClickMenu({ nameEn: parentMenuName })">ALL&nbsp;{{ parentMenuName }}</div>
            <div v-for="menu in currentList" class="d-flex justify-space-between mb-5" @click="handleClickMenu(menu)">
              <p>{{ menu.nameEn }}</p>
              <v-icon v-if="menu.children && menu.children.length" icon="mdi-chevron-right" class="ms-4"></v-icon>
            </div>
          </div>
        </template>
      </shopmall-drawer>
      <div reporter-module="header_search_input" class="search">
        <div class="icon" @click="onClick(keyword)">
          <v-icon icon="mdi-magnify" size="28"></v-icon>
        </div>
        <input v-model="keyword" type="text" v-on:keydown="onSearch" placeholder=" products, sku or keywords" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useDisplay } from "vuetify"
import { promotionsInteractor, systemInteractor, productInteractor } from "~/core"
import { authInteractor } from "~/core"
import { useUserStore, useProductStore, useCartStore, useSearchStore, useGlobalStore } from "~/stores"
import { fetchInProgress } from "~/core/server"
import register from "~/assets/svg/register.svg"
import cartSvg from "~/assets/svg/cart.svg"
import orderTrackSvg from "@/assets/svg/order-tracking.svg"
import signinSvg from "@/assets/svg/signin.svg"
import { SearchClickReport, SearchSuccessReport, SearchResultReport, trackSearch } from '~/utils/report/search'

const gStore = useGlobalStore()
const { width } = useDisplay()
watch(
  () => width.value,
  (v) => {
    gStore.setDeviceType(v >= 1280)
  }
)

const { $MallPageName, $ClickSource, $BaseClickBurying, $Message, $Utils, $multiPlatform, $Buryingpoint, $Reporter } = useNuxtApp()
const primaryColor = $multiPlatform.primaryColor
const logoSrc = $multiPlatform.logoNew
const cartStore = useCartStore()
const route = useRoute()
const router = useRouter()
const keyword = ref("")
const menuDrawer = ref(null)
const orderInProgressList = ref({}) //折扣活动
const showMenuDrawer = () => {
  menuDrawer.value.show()
}

// 获取购物车数量
const cart = useCart()
cart.initCartData(false)

// 定义menus菜单，菜单项可能有children
// 给menus每个一级元素添加level:1属性
const category = useCategory()
const productStore = useProductStore()

const list = [
  { nameEn: "Products", underlineVisible: false, children: [], level: 1, id: 1 },
  { nameEn: "New Arrivals", underlineVisible: false, children: [], level: 1, id: 2 },
  { nameEn: "Brands", underlineVisible: false, children: [], level: 1, id: 3 },
  { nameEn: "Featured Products", underlineVisible: false, children: [], level: 1, id: 4 },
]
// 菜单
const menus = ref(list)
const currentList = ref([]) // 当前list
const parentMenuName = ref("") // 父菜单名称
const productIds = ref([]) // 产品id集合
const brandIds = ref([]) // 品牌id集合

// 商城-查询进行中的订单折扣
const getOrderInProgressFn = async () => {
  const cachedOrderInProgress = localStorage.getItem("orderInProgressList")
  if (cachedOrderInProgress) {
    orderInProgressList.value = JSON.parse(cachedOrderInProgress)
  }
  const [res, err] = await promotionsInteractor.orderInProgressApi()
  if (res) {
    orderInProgressList.value = res
    localStorage.setItem("orderInProgressList", JSON.stringify(res))
  } else if (!res && !err) {
    localStorage.removeItem("orderInProgressList")
    orderInProgressList.value = []
  }
}

onMounted(() => {
  getOrderInProgressFn()
  // getMenus()
  generateNav()
})

// 获取Products下面的分类
const getCategorys = async () => {
  const [list, err] = await productInteractor.categorysApi()
  if (!err && list?.length) {
    // 清除第三级分类
    list.forEach((item) => {
      if (item.children?.length) {
        item.children.forEach((subItem) => {
          subItem.children = null
        })
      }
    })
    productIds.value = list.map((n) => n.id)
    return list
  } else {
    return []
  }
}

// 获取Brands下面的分类
const getBrands = async () => {
  return [] // brands下面的一级分类不需要显示
  // 目前产品需要将brands下面的一级分类隐藏，如果需要显示，需要将下面的注释打开
  // const [list, err] = await productInteractor.productBrandsApi()
  // if (!err && list?.length) {
  //   brandIds.value = list.map((n) => n.id)
  //   return list
  // } else {
  //   return []
  // }
}

// 获取获取Featured Products下面的分类
const getFeaturedProducts = async () => {
  const [list, err] = await productInteractor.featuredTagsApi()
  if (!err && list?.length) {
    return list
  } else {
    return []
  }
}

// 生成导航栏
const generateNav = async () => {
  const [categorys, brands, featuredProducts] = await Promise.all([getCategorys(), getBrands(), getFeaturedProducts()])
  // 目前产品需要将brands下面的一级分类隐藏，如果需要显示，需要将下面的注释打开
  // if (!brands.length) {
  //   menus.value = menus.value.filter((n) => n.nameEn !== "Brands")
  // }
  if (!featuredProducts.length) {
    menus.value = menus.value.filter((n) => n.nameEn !== "Featured Products")
  }
  for (const item of menus.value) {
    if (item.nameEn === "Products") {
      item.children = categorys
    } else if (item.nameEn === "Brands") {
      item.children = brands.map((n) => ({ id: n.id, nameEn: n.brandName }))
    } else if (item.nameEn === "Featured Products") {
      item.children = featuredProducts.map((n) => ({ nameEn: n.tagName, queryTypeName: n.tagName, queryType: n.queryType }))
    }
  }
  currentList.value = menus.value
}

// 查找父级节点
const findParent = (tree, targetId, parent = null) => {
  for (const node of tree) {
    if (node.id === targetId) {
      return parent
    }
    if (node.children) {
      const result = findParent(node.children, targetId, node)
      if (result) {
        return result
      }
    }
  }
  return null
}

// 查找当前节点和其所有兄弟节点
const findSiblings = (tree, targetId) => {
  const ids = tree.map((n) => n.id)
  if (ids.includes(targetId)) return tree
  for (const node of tree) {
    if (node.children) {
      for (const child of node.children) {
        if (child.id === targetId) {
          return node.children
        }
      }
      const result = findSiblings(node.children, targetId)
      if (result) {
        return result
      }
    }
  }
  return []
}

// 菜单项点击
const handleClickMenu = (menu) => {
  let { level, nameEn, id, children, queryTypeName, queryType } = menu
  if (children?.length) {
    currentList.value = children
    parentMenuName.value = nameEn
  } else {
    if (["Products", "Featured Products"].includes(nameEn)) {
      return
    }
    showMenuDrawer()
    // 跳转
    // 导航中只有New Arrivals和brands可以点击跳转
    const source = route.name === "index" ? { source: 'new_home' } : {}
    if (level === 1 && nameEn === "New Arrivals") {
      router.push({ path: "/new-arrivals", query: source })
      return
    }
    if (level === 1 && nameEn === "Brands") {
      router.push({ path: "/brands", query: source })
      return
    }
    // 如果是Brands下的分类，点击跳转
    if (brandIds.value.includes(id)) {
      router.push({ path: "/brands", query: { brandName: nameEn, id, ...source } })
      return
    }
    // 如果是Featured Products下的分类，点击跳转
    if (queryTypeName) {
      router.push({ path: "/featured-products", query: { queryTypeName: encodeURIComponent(queryTypeName), queryType, ...source } })
      return
    }
    // 如果是Products下的分类，点击跳转
    if (nameEn) {
      nameEn = $Utils.replaceSpacesWithDash(nameEn, false)
      if (route.name === "index") {
        router.push({ path: `/category/${nameEn}`, query: source })
      } else {
        router.push({ path: `/category/${nameEn}` })
      }
    }
  }
}

// 返回
const back = () => {
  const parent = findParent(menus.value, currentList.value[0].id)
  const siblings = findSiblings(menus.value, parent.id)
  const grandFather = findParent(menus.value, parent.id)
  currentList.value = siblings
  parentMenuName.value = grandFather?.nameEn
}

/**
 * 跳转到搜索页
 * @param {keyword} 关键词
 */
const searchStore = useSearchStore()
const onClick = async (keyword) => {
  if (!keyword?.trim()) {
    $Message.error("Please enter a valid search term.")
    return
  }

  // 搜索按钮点击事件埋点
  $BaseClickBurying($MallPageName.mallHeader, $ClickSource.mallHeaderSearch)
  $Reporter.click({event: 'e_header_search_btn'})

  // 获取版本号
  const [searchVersion, err] = await systemInteractor.searchVersionApi()
  if (searchVersion) {
    localStorage.setItem("searchVersion", searchVersion)
  } else {
    localStorage.removeItem("searchVersion")
  }

  // 判断搜索是否是通过用户交互触发的标示
  localStorage.setItem("searchSourceClick", 1)

  // 搜索直接跳转到商品详情页
  const searchParams = { keyWord: keyword, current: 1, size: 40, orders: [] }
  const [res, error] = await productInteractor.productPage(searchParams)
  if (res && res.total === 1 && res.precisionSearch) {
    const product = res.records[0]
    SearchClickReport(keyword, true)
    SearchSuccessReport(keyword, true)
    SearchResultReport(keyword, true)
    $Buryingpoint.searchKeywordProductClick({
      search_keyword: keyword,
      product_name: product.nameEn,
    })
    trackSearch(keyword, product.nameEn)
    navigateTo(`/product/${product.code}_${$Utils.replaceSpacesWithDash(product.nameEn)}${`?t=s&k=${keyword}&source=header_search`}`)
  } else if (res) {
    searchStore.setSearchResult(res)
    // 重复的请求数据也可以搜索
    route.query.keyword = ""
    const queryKeyword = encodeURIComponent(keyword)
    navigateTo(`/search?keyword=${queryKeyword}`)
  }
}

/**
 * 搜索栏键入事件
 * @param {event}
 */
const onSearch = (event) => {
  if (event.key === "Enter") onClick(keyword.value)
}

// 登录状态
const store = useUserStore()

// 登出
const signOut = async () => {
  const [res, err] = await authInteractor.loginOutApi()
  if (res === "") {
    store.resetAll()
    if (route.path.match("product") || route.path.match("category") || route.path.match("search") || window.location.pathname === "/") {
      window.location.reload()
    } else {
      router.push({
        name: "index",
      })
    }
  }
}

// 跳转到首页
const goHome = () => {
  if (router?.currentRoute?.value?.name === "index") {
    const link = document.createElement("a")
    link.href = "/"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } else {
    navigateTo("/")
  }
}
const copyFn = async (val) => {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(val)
    $Message.success("Get Success")
  } else {
    var tempTextArea = document.createElement("textarea")
    tempTextArea.value = val
    document.body.appendChild(tempTextArea)
    tempTextArea.select()
    document.execCommand("copy")
    document.body.removeChild(tempTextArea)
    $Message.success("Get Success")
  }
}
</script>
<style lang="scss" scoped>
.page-header-new {
  .top-right-link {
    a, a:hover {
      text-decoration: none;
    }
  }
  .btn-wrapper {
    display: inline-block;
  }
  .header-container {
    /* height: 80px; */
    padding-top: 20px;
    --primary-color: v-bind(primaryColor);
    background-color: #00bbef;
    @media (max-width: 1500px) {
      .header-top {
        padding: 0 5%;
      }
    }
    @media (min-width: 1500px) {
      .header-top {
        padding: 0 10%;
      }
    }
  }
  .v-divider {
    height: 14px;
  }
  .v-btn {
    font-size: 14px;
  }
  .search-input {
    position: relative;
    width: 300px;
    margin-right: 30px;
    input {
      height: 44px;
      width: 100%;
      border-radius: 22px;
      border: 1px solid white;
      padding: 0 60px 0 16px;
      font-size: 14px;
      outline: none;
      color: white;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
      &:focus {
        border-width: 2px;
      }
    }
    .icon {
      position: absolute;
      right: 1px;
      top: 1px;
      width: 50px;
      height: calc(100% - 2px);
      color: white;
      background-color: rgba(0, 0, 0, .2);
      border-radius: 0 25px 25px 0;
      border-left: 1px solid rgba(255, 255, 255, 0.7);
      cursor: pointer;
      .v-icon {
        line-height: 100%;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .nopc-device {
    background-color: #00bbef;
    color: #fff;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 54px;
      padding: 0 5vw;
      > img {
        max-width: 160px;
        cursor: pointer;
      }
      .icons {
        display: flex;
        align-items: center;
      }
    }
    .search {
      position: relative;
      padding: 8px 5vw !important;
      input {
        height: 44px;
        width: 100%;
        border-radius: 22px;
        border: 1px solid white;
        padding: 0 50px 0 14px;
        font-size: 14px;
        outline: none;
        color: white;
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .icon {
        position: absolute;
        top: 9px;
        right: calc(5vw + 1px);
        width: 44px;
        height: 42px;
        color: white;
        background-color: rgba(0, 0, 0, .2);
        border-radius: 0 25px 25px 0;
        border-left: 1px solid rgba(255, 255, 255, 0.7);
        .v-icon {
          line-height: 100%;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
    .search {
      padding: 8px;
      border-bottom: 1px solid #e5e5e5;
    }
  }
  :deep(.v-label) {
    font-size: 14px;
  }
  .menu-wrapper {
    color: #555;
    .menu-title {
      background-color: #f2f2f2;
    }
  }
  // 在手机端时，设置width为75%；在pad端时，width为780
  @media (min-width: 100px) and (max-width: 1050px) {
    :deep(.v-navigation-drawer) {
      width: 75% !important;
    }
  }

  :deep(.mobile-header-search .v-field__overlay) {
    border: 1px solid #d7d7d7;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
  .active-list {
    width: 100%;
    background: #016490;
    .active-list-item {
      text-align: center;
      color: #fff;
      font-size: 19px;
      line-height: 32px;
      a {
        color: #fff;
      }
    }
    .underline {
      text-decoration: underline;
    }
  }
}
</style>
