import {customerInteractor} from "~/core"

const promiseCache = new Map()

async function getCachedPromise(key) {
  if (!promiseCache.has(key)) {
    const [res] = await customerInteractor.registerMailRuleApi()
    if(res) {
      promiseCache.set(key, res)
    }
  }
  return promiseCache.get(key)
}

export function clearCachedPromise(key) {
  promiseCache.delete(key);
}

export default getCachedPromise